import { render, staticRenderFns } from "./setExamTime.vue?vue&type=template&id=4cfb8ce5&scoped=true&"
import script from "./setExamTime.vue?vue&type=script&lang=js&"
export * from "./setExamTime.vue?vue&type=script&lang=js&"
import style0 from "./setExamTime.vue?vue&type=style&index=0&id=4cfb8ce5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cfb8ce5",
  null
  
)

export default component.exports