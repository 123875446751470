<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-05-07 16:39:50
-->
<template>
  <div>
  <el-dialog
    :title="propTitle"
    :visible.sync="dialogVisible"
    width="550px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="arrangeForm"
      :rules="rules"
      ref="arrangeForm"
      label-width="80px"
      >
      <el-form-item label="考试人员" prop="employeeIds">
        <el-select v-model="arrangeForm.employeeIds" value-key="groupSort" placeholder="请选择" style="width:100%;" multiple :disabled="settingType==='singleSetting'">
          <el-option
            v-for="item in studentList"
            :key="item.employeeId"
            :value="item.employeeId"
            :label="item.employeeName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试时间" prop="startTime">
        <el-date-picker
          style="width: 100%;"
          v-model="examTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="_selectExamTime">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="_submit()">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import { getCurrentTime } from '@/utils/util'
export default {
  components: {
  },
  props: {
    propTitle: {
      type: String,
      default: ''
    },
    settingType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      examTime: '',
      currentExamUser: {}, // 重新考试--当前考试人员
      studentList: [], // 考生列表
      dialogVisible: false,
      rules: {
        employeeIds: [
          { required: true, message: '请选择', trigger: ['change', 'blur'] }
        ],
        startTime: [
          { required: true, message: '请选择', trigger: ['change', 'blur'] }
        ]
      },
      arrangeForm: {
        examInfoId: '', // 考试id
        employeeIds: [], // 考生id
        startTime: '', // 考试开始时间
        endTime: ''// 考试结束时间
      }
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    // 选择考试时间
    _selectExamTime (a) {
      if (a) {
        const examStartTime = new Date(a[0]).getTime()// 考试设置的开始时间
        const examEndTime = new Date(a[1]).getTime()// 考试设置的截止时间
        const currentTime = new Date(getCurrentTime()).getTime()// 当前时间
        if (examStartTime < currentTime || examEndTime < currentTime) {
          this.examTime = ''
          return this.$message.error('考试开始和结束时间不能小于当前时间！')
        }
        this.arrangeForm.startTime = a[0]
        this.arrangeForm.endTime = a[1]
      } else {
        this.arrangeForm.startTime = ''
        this.arrangeForm.endTime = ''
      }
    },
    show (row, studentInfo) {
      if (studentInfo) { // 批量设置考试时间
        this.studentList = studentInfo
        if (row) { // 单个人--重新考试
          this.currentExamUser = row
          this.arrangeForm.employeeIds = [row.employeeId]// 回显人员
          this.$forceUpdate()
        } else {
          this.arrangeForm.employeeIds = []
        }
      }
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    _submit () {
      this.$refs.arrangeForm.validate((valid) => {
        if (valid) {
          this.arrangeForm.examInfoId = this.$route.query.examId
          if (this.settingType === 'multipleSetting') { // 批量设置考试时间
            this.$emit('settingExamTime', { ...this.arrangeForm })
            this.dialogVisible = false
          } else { // 单个设置考试时间
            this.$emit('againStartExam', { ...this.arrangeForm, employeeId: this.arrangeForm.employeeIds[0] })
            this.dialogVisible = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.select-box-info{
  .el-tag{
    width: 78px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    margin-right: 12px;
    font-size: 14px;
  }
}
</style>
